import './app.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

function ConfirmationLogin() {
    //Cookies.set('user_id', "1");  
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const userId = Cookies.get('user_id');

    // Step 1: Fetch the username based on the user ID
    const fetchUsername = async () => {
        try {
            const response = await axios.post(`http://localhost:8083/api/user-read-by-id/`, { _id: userId });

            if (response.status >= 200 && response.status < 300) {
                const username = response.data.username; // Extract username from the response
                return username;
            } else {
                setMessage(`Unexpected response status: ${response.status} while fetching username.`);
                return null;
            }
        } catch (error) {
            console.error('Error fetching username:', error);
            setMessage('Error fetching username');
            return null;
        }
    };

    // Step 2: Fetch user type based on the username
    const fetchUserType = async (username) => {
        try {
            const response = await axios.get(`http://localhost:8083/api/user-get-type/${username}`);

            if (response.status >= 200 && response.status < 300) {
                const userType = response.data.user_type; // Extract user_type from the JSON response

                if (userType) {
                    setMessage(`Welcome ${username}`);

                    // Delay the redirection by 1 second (1000 ms)
                    setTimeout(() => {
                        if (userType === 'Advertiser') {
                            navigate('/advertiser-home');
                        } else if (userType === 'Publisher') {
                            navigate('/publisher-home');
                        } else {
                            setMessage('Unknown user type');
                        }
                    }, 3000);
                } else {
                    setMessage('Failed to determine user type');
                }
            } else {
                setMessage(`Unexpected response status: ${response.status} while fetching user type.`);
            }
        } catch (error) {
            console.error('Error fetching user type:', error);
            setMessage('Error fetching user type.');
        }
    };

    // Main function to perform process
    const initiateUserRedirection = async () => {
        const username = await fetchUsername();
        if (username) {
            await fetchUserType(username);
        }
    };

    useEffect(() => {
        if (userId) {
            initiateUserRedirection();
        }
    }, [userId]);

    return (
        <div className="confirmation-login">
            <Header />

            <div className="confirmation-content">
                <h1 className="message">{message}</h1>
                <p> redirecting you to your home page...</p>
            </div>

            <Footer />
        </div>
    );
}

export default ConfirmationLogin;
