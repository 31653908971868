import './app.css';
import React, { useState } from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate  } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

function AdvertiserSignup(){

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [personalName, setPersonalName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isSelected, setIsSelected] = useState(false);
    const [hasInteracted, setHasInteracted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Error creating account, passwords do not match');
            return;
        }

        try {
            const response = await axios.post('http://localhost:8083/api/user-create/', {
                personal_name: personalName,
                username,
                email,
                company_name: companyName,
                user_type: "Advertiser",
                password
            });
    
            if (response.status >= 200 && response.status < 300) {
                
                Cookies.set('user_id', response.data._id, {expires:365, sameSite:'strict', secure: true});
                setMessage('User created successfully!');

                setUsername('');
                setPersonalName('');
                setEmail('');
                setCompanyName('');
                setPassword('');

                Cookies.set('username', username,  { path: '/email-confirmation', expires:365, sameSite:'strict', secure: true});
                Cookies.set('password', password,  { path: '/email-confirmation', expires:365, sameSite:'strict', secure: true});
                navigate('/email-confirmation');


            } else {
                setMessage('Failed to create user. Please try again.');
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            setMessage('Error creating user. Please try again.');
            if (error.response) {
                console.error('Error response:', error.response.data);
            } else {
                console.error('Error:', error);
            }
        }
    }



    return (
        <div className='advertiser-signup'>
            <Header />

            <p>
                Your Gateway to Respectful Advertising! <br/><br/>
                Become an advertiser with Encryptiest
            </p>

            <form onSubmit={handleSubmit}>
                <div className='advertiser-signup-form'>
                    
                    <label htmlFor="username">Username:</label>
                    <br></br>
                    <input 
                        type='text'
                        id='username'
                        className='signup-textbox'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    ></input>
                    <br></br> 

                    <label htmlFor="email">Email:</label>
                    <br></br>
                    <input 
                        type='text'
                        id='email'
                        className='signup-textbox'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    <br></br>

                    <label htmlFor="password">Password:</label>
                    <br></br>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        id='password'
                        className={`signup-textbox ${hasInteracted && (passwordsMatch ? 'valid' : 'invalid')}`}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                            setPasswordsMatch(e.target.value === confirmPassword);
                        }}
                        onFocus={() => setIsSelected(true)}
                        onBlur={() => {
                            if (isSelected) {
                                setHasInteracted(true);
                            }
                            setIsSelected(false);
                        }}
                    />
                    <span className="signup-toggle-password" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 'Hide' : 'Show'}
                    </span>
                    <br></br>

                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <br></br>
                    <input
                        type={showConfirm ? 'text' : 'password'}
                        id='confirmPassword'
                        className={`signup-textbox ${hasInteracted && (passwordsMatch ? 'valid' : 'invalid')}`}
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value)
                            setPasswordsMatch(e.target.value === password);
                        }}
                        onFocus={() => setIsSelected(true)}
                        onBlur={() => {
                            if (isSelected) {
                                setHasInteracted(true);
                            }
                            setIsSelected(false);
                        }}
                    />
                    <span className="signup-toggle-password" onClick={() => setShowConfirm(!showConfirm)}>
                        {showConfirm ? 'Hide' : 'Show'}
                    </span>
                    <br></br>

                    <label htmlFor="personalname">Name:</label>
                    <br></br>
                    <input 
                        type='text'
                        id='personalname'
                        className='signup-textbox'
                        value={personalName}
                        onChange={(e) => setPersonalName(e.target.value)}
                    ></input>
                    <br></br>
                    
                    <label htmlFor="companyname">Company Name:</label>
                    <br></br>
                    <input 
                        type='text'
                        id='companyname'
                        className='signup-textbox'
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    ></input>
                    <br></br>
                </div>

                <button type='submit' className='signup-submit'>Sign Up</button>
                <p>{message}</p>
            </form>

            <Footer />

 
        </div>
    )
}

export default AdvertiserSignup;