
import React, { useState } from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'
import Header from './header';
import Footer from './footer';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
function Login(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [userType, setUserType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const isValid = checkEmpty();
        if (!isValid){
            setErrorMessage('Login failed. Please check your username and password.');
            return;
        }

        try {
            const loginResponse = await axios.post('http://localhost:8083/api/user-login/', {
                username,
                password
            });
            
            const userResponse = await axios.get(`http://localhost:8083/api/user-get-type/${username}`, {});
            
            if (loginResponse.status >= 200 && loginResponse.status < 300 && userResponse.status >= 200 && userResponse.status < 300){
                
                Cookies.set('user_id', userResponse.data._id, {secure:true, sameSite:'strict', expires:365});
                
                setUsername('');
                setPassword('');
                setErrorMessage('');
                
                if (userResponse.data.user_type == "Advertiser"){
                    setUserType(userResponse.data.user_type);
                    navigate('/advertiser-home');
                    
                }
                else if (userResponse.data.user_type == "Publisher"){
                    setUserType(userResponse.data.user_type);
                    navigate('/publisher-home');
                }
                else {
                    console.log("Could not recognize userType.");
                }

            }
            else {
                setErrorMessage('Login failed. Please check your username and password.');
            }


        }
        catch (error) {
            if (error.response){
                console.error('Error response:', error.response.data);
                setErrorMessage('Login failed. Please check your username and password.');
            }
            else {
                console.error('Error:', error);
            }
        }
    }

    const checkEmpty = () => {
        let isValid = true;
        if (username === '' || password === ''){
            isValid = false;
        }
        return isValid;
    }

    return (
        
        <div className="login-page min-vh-100 d-flex flex-column">
        <Header/>
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <div className="login-container">
            {errorMessage && (
              <div className="login-error mb-3">
                <h1>{errorMessage}</h1>
              </div>
            )}
            
            <h2 className="login-title text-center">Account Login</h2>
            <p className='login-title-two text-center'>Please enter your login credentials.</p>
            
            <form onSubmit={handleSubmit}>
              <div className="mb-3 login-input">
                <input 
                  id='username' 
                  className="form-control"
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter Username"
                />
              </div>
      
              <div className="mb-3 login-input">
                <input 
                  id='password' 
                  className="form-control"
                  type="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                />
              </div>

              <div className = "login-prompt d-flex justify-content-between">
                <p className = "text-primary">Forgot Password?</p>
                <p>Remember Me</p>
              </div>

            
              <div className='d-flex flex-column justify-content-center align-items-center'>
              <button type='submit' className="btn btn-primary w-100 ">Sign in</button>
              {/* <p className = "mt-4">or login with Google</p> */}
              </div>
              <p className='create-account text-center'>Don't have an account? Register Here!</p>
            </form>
          </div>
        </div>
      </div>

    )
}
export default Login;