import './app.css';
import Cookies from 'js-cookie';
import logo from './header2.png';
import Header from './header';
import Footer from './footer';
import React, {Component} from 'react';
var message = "";


const acceptCookies = () => {
    Cookies.set('consent', 'true', {expires:365, sameSite:'strict', secure: true});
    window.location.reload();
    return (
        console.log("User accepted Encryptiest cookies")
    );


}


const rejectCookies = () => {
    Cookies.set('consent', 'false', {expires:365, sameSite:'strict', secure: true});
    window.location.reload();
    return (
        console.log("User rejected Encryptiest cookies")
    );


}


const checkConsentExists = (cookie) => {
    console.log("Checked for consent cookie");


    var choice = '';


    const setMessage = (cookie) =>{
        if (Cookies.get(cookie) == 'false'){
            message = "Encryptiest's cookies were declined";
            choice = 'false';
        }
        else if (Cookies.get(cookie) == 'true'){
            message = "Encryptiest's cookies were accepted :)";
            choice = 'true';
        }
    }


    setMessage(cookie);


    if (choice == 'true' || choice == 'false'){
        return (
            <div>
                <Header />
            <div className="consentpopup">
                <h1>Thank you for your selection.</h1>


                <h1>{message}</h1>
                <h1>But if you'd still like to learn more about Encryptiest, click <a href="/introduction" target="_blank">here.</a></h1>
                <h1>If you'd like to reconsider your decision, click one of the buttons below:</h1>
                <div className="consent-button-selections">
                    <button className="consent-reject-button" onClick={()=>rejectCookies()}>Reject Encrypted Cookies</button>
                    <button className="consent-accept-button" onClick={()=>acceptCookies()}>Accept Encrypted Cookies</button>
                </div>
               
            </div>
            <Footer />
            </div>
        );
    }
    else {
        return (
            <div className="consentpopup">
                {ConsentPopupMessage()}
            </div>
        );
    }
}


const ConsentPopupMessage = () => {
    return (
        <main className='consentpopup2'>
            <h2>We won't store any of your information without your consent. Even if you do give your consent, we won't share your information with anyone but </h2>
            <img src={logo} alt="Encryptiest logo"></img>
            <h2>Learn more about our innovative approach to respecting your privacy <a href="/introduction" target="_blank">here.</a></h2>
            <h2>Or if you feel like you already know enough, please make your decision below: </h2>
                <div className="consent-button-selections">
                    <button className="consent-reject-button" onClick={()=>rejectCookies()}>Reject Encrypted Cookies</button>
                    <button className="consent-accept-button" onClick={()=>acceptCookies()}>Accept Encrypted Cookies</button>
                </div>
        </main>


    );
}


function ConsentPopup(){


    return (
        checkConsentExists('consent')
    );


};


export default ConsentPopup;
