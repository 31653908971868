import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState, useEffect } from 'react';
//react bootstrap component library
import 'bootstrap/dist/css/bootstrap.min.css';
import WhiteHeader from "./whiteHeader";


const GetStarted = () => {
    const handleEmailClick = () => {
        window.location.href = 'mailto:hello@encryptiest.com?subject=Inquiry Regarding Encryptiest Account Creation&body=';
    };
    return(
        
        <div className = "starting-page">
        <WhiteHeader></WhiteHeader>
            <div className = "page d-flex flex-column justify-content-center align-items-center ">
            
                <h1>Thank you for choosing Encryptiest.</h1>
                <p>For the time being, we are only conducting private demos.</p>
                <p>If you would like to schedule a demo, please don't hesitate to contact us!</p>
                <button className = "black-button" onClick={handleEmailClick}>
                    Contact
                </button>
            </div>
        </div>
    );

}

export default GetStarted;
