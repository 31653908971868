import './app.css';
import React, { useState , useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

function AdvertAdd() {
  const [username, setUsername] = useState('');
  const [textValue, setTextValue] = useState('');
  const [descriptors, setDescriptors] = useState('');
  const [fileBlob, setFileBlob] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  
  const [errorMessage, setErrorMessage] = useState('');
  const [textFieldError, setTextFieldError] = useState('');
  const [fileError, setFileError] = useState('');
  const navigate = useNavigate();
  

  {/* ---------- CONTENT BLOCK ---------- */}
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  //    \/ \/ \/ \/ \/ \/ \/ \/ \/ \/ \/   //

  const [userType, setUserType] = useState(''); // Tracks user type

  useEffect(() => {
    // Create a new user in /api/user-create with type Advertiser.
    // Uncomment test case and input your created username
    // and comment out the other line to test ur user.
    // Create another user with type Publisher to test blocking function on publishers.
    // Test a random username that isn't valid to see if it blocks users not logged in.

    const username = Cookies.get('username');
    //const username = 'test_username'; //testing line

    console.log('Retrieved user_id from cookies:', username); // Prints cookie in log

    if (!username) {
      console.log('No username found, redirecting to signup...'); // Debugging
      navigate('/advertiser-redirect');
    } else {
      axios.get(`http://localhost:8083/api/user-get-type/${username}`)

      .then(response => {
        const {user_type} = response.data;

        if (!response.data || !user_type) {
          // Handle case where username is invalid or doesn't exist
          console.log('Username does not exist, redirecting...');
          navigate('/advertiser-redirect');
        } 
        else if(user_type == 'Advertiser'){
          setUserType(user_type);
          console.log('User is an Advertiser'); // Debugging
        } else {
          console.log('User is not an Advertiser, redirecting...'); // Debugging
          navigate('/advertiser-redirect');
        }
      })
      .catch(error => {
        console.error('Error fetching user type or username does not exist:', error);
        navigate('/advertiser-redirect');
      });
    }
  }, [navigate,username]);
  
  //    /\ /\ /\ /\ /\ /\ /\ /\ /\ /\ /\   // 
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  {/* ------ END OF CONTENT BLOCK ------- */}


  /*
    No current ids exist in the user table
  Cookies.set('user_id', '0', {expires:365});
  const userId = Cookies.get('user_id');
  */
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const isValid = checkEmpty();
    if (!isValid) {
      return;
    }

    try {
      /*
      No current ids exist in the user table
      This reads the user_id cookie
      
      const userResponse = await axios.post('http://localhost:8083/api/user-read-by-id/',
      {
        _id: userId
        });
      
      Insert into adReponse
        user_id: userResponse.data.userId,
      */
      const adResponse = await axios.post('http://localhost:8083/api/advertisement-create/', {
        user_id: 0,
        name: textValue,
        image: imageUrl,
        descriptors
      });

      if (adResponse.status >= 200 && adResponse.status < 300) {
        Cookies.set('user_id', adResponse.data._id, {expires:365});

        setUsername('');
        setTextValue('');
        setImageUrl('');
        

        navigate('/advertisers-home');
      } 
      else {
        setErrorMessage('Could not create advertisement.');
      }
    } 
    catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
      } 
      else {
        console.error('Error:', error);
      }

      setErrorMessage('Could not create advertisement.');
    }
  }

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match('image.*')) {
      const blob = new Blob([file], {type: file.type});
      setFileBlob(blob);
      const url = URL.createObjectURL(blob);
      setImageUrl(url);
    } 
    else {
      alert('Please select a valid image file (PNG).');
    }
  }

  const checkEmpty = () => {
    let isValid = true;
    if (textValue === '') {
      setTextFieldError('The text field is empty.');
      isValid = false;
    } 
    else {
      setTextFieldError('');
    }

    if (imageUrl === '') {
      setFileError('No file was uploaded.');
      isValid = false;
    } 
    else {
      setFileError('');
    }

    return isValid;
  }

  return (
    <div className="advertiseradd">
      <Header />
      <main>
        <h1>Let's get your new ad into the system!</h1>

        <form onSubmit={handleSubmit}>
          <div className="advertiseradderror">
            {errorMessage && <h1>{errorMessage}</h1>}
            {textFieldError && <h1>{textFieldError}</h1>}
          </div>

          <div className="advertiseraddname">
            <input 
              type="text" 
              name="adname" 
              value={textValue} 
              onChange={handleTextChange}
            />
            <h1>Advertisement Name</h1>
          </div>

          <div className="advertiseradderror">
            {fileError && <h1>{fileError}</h1>}
          </div>

          <div className="advertiseraddfile">
            <input 
              type="file" 
              onChange={handleFileChange}
              id="advertisement_image" 
              name="advertisement_image" 
              accept="image/jpeg, image/png, image/gif" 
            />
            <button type="submit">Create Advertisement</button>
          </div>
        </form>
      </main>
      <Footer />
    </div>
  );
}

export default AdvertAdd;