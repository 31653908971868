import React from 'react';
import WhiteHeader from './whiteHeader';
import Footer from './footer';
import 'bootstrap/dist/css/bootstrap.min.css';
//icons import
import { Clock } from 'lucide-react';
import { MapPinHouse } from 'lucide-react';
import { MoveUpRight } from 'lucide-react';

const JobListing = ({ title, description, fullTime }) => (
  <div className="job-listing mb-4 pb-4 border-bottom">
    <div className='d-flex justify-content-between'>
    <h3>{title}</h3>
    <h3>Apply <MoveUpRight strokeWidth = {3}/></h3>
    </div>
    <p>{description}</p>
    <div className="d-flex align-items-center">
      {/* <span className={`badge ${fullTime ? 'bg-primary' : 'bg-light text-dark border'} me-3`}>Full-Time</span> */}
      <button className="job-tags"><Clock className='clock-time'/>Full Time</button>
      <button className="job-tags"><MapPinHouse/>Fully-Remote</button>
    </div>
  </div>
);

function Careers() {
  return (
    <div className='careers'>
      <WhiteHeader />
      
      <div className="container mt-5">
        {/* <h1 className="mb-3">Careers</h1> */}
        <div className="career-title d-flex flex-column ">
          <h2 className="mb-3">Find a job by joining our team!</h2>
          <p className="lead">We are looking for passionate and talented individuals to join our team.</p>
        </div>

        <JobListing 
          title="Software Engineer"
          description="We are looking for a skilled Software Engineer to join our growing team. You will work on cutting-edge technologies to ensure our platform remains secure and scalable. Responsibilities include developing new features, optimizing performance, and maintaining existing code."
          fullTime={true}
        />

        <JobListing 
          title="Product Manager"
          description="We are seeking a Marketing Specialist to help us grow our brand. You will create and execute marketing campaigns that promote our privacy-focused solutions, engage with our community, and help drive user acquisition."
          fullTime={true}
        />

        <JobListing 
          title="Customer Support Specialist"
          description="Join our team as a Customer Support Specialist, where you'll provide assistance to our users, troubleshoot issues, and ensure that all customer inquiries are resolved promptly and professionally."
          fullTime={true}
        />
      </div>

      <Footer />
    </div>
  );
}

export default Careers;