import './app.css';
import { Link } from 'react-router-dom';
import PrototypeLogo from './PrototypeLogo.png';
import { HashLink } from 'react-router-hash-link';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Container, Navbar, Nav } from 'react-bootstrap';

const WhiteHeader = () => {
    const [expanded, setExpanded] = useState(false);

    const handleButtonClick = () => {
        console.log("click test passed!");
    };

    return (
       
            <Navbar 
                expanded={expanded} 
                expand="lg" 
                style={{ backgroundColor: 'white', color: "#121212"}}
                className="p-3"
                id = "navbar-color"
            >   
                <Container fluid>
                    <Navbar.Brand href="/">
                    <span style={{ fontFamily: 'Space-mono', fontSize: '24px', fontWeight: 'bold',color: '#121212',marginLeft: '5%' }}>
                        encryptiest.
                    </span> 
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" id = "nav-custom2">
                            <Nav.Link href="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
                            <Nav.Link href="/about" onClick={() => setExpanded(false)}>About</Nav.Link>
                            <Nav.Link href="/news" onClick={() => setExpanded(false)}>News</Nav.Link>
                            <HashLink to="/#faq-section" className="nav-link"> FAQ</HashLink>
                        </Nav>
                        <Nav className = "justify-content-center align-items-center" id = "nav-custom2">
                            <Nav.Link href="/login" onClick={() => setExpanded(false)}>Login</Nav.Link>
                            < Link to="/get-started">
                            <Button
                                onClick={handleButtonClick}
                                className = "black-button"
                            >
                                Get Started
                            </Button>
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


    );
}

export default WhiteHeader;