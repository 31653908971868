import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import Header from './header';
import Footer from './footer';
import { Button } from "react-bootstrap";

function sanitizeMarkdown(markdown) {
    return DOMPurify.sanitize(marked(markdown));
}

function Investors() {
    let [content, setContent] = useState('');

    useEffect(() => {
        // You can fetch markdown content from a file or an API endpoint
        const fetchData = async () => {
            const response = await fetch('/path-to-your-investors-markdown.md'); // Update this path
            const text = await response.text();
            setContent(text);
        };

        fetchData();
    }, []);

    return (

        
        <div className='investors'>
        <Header />
        <div className = "d-flex align-items-center justify-content-center hero-section2">
        <div className = "d-flex flex-column align-items-center text-center" id = "slogan">
         <h1>
           We value your privacy.
         </h1>
         <p>Applying the theory of Fully Homomorphic Encryption to realize a better internet for all.</p>

         <Button 
    
             className = "blue-button"
         >
             Join Now
         </Button>
    
            </div>
        </div>
            
        <div className="spacer layer6"></div>

            <div className='investors-title'>
                <h1>Invest in the Future of Privacy-Protected Advertising</h1>
                <p>
                        Encryptiest is pioneering a new era of advertising that prioritizes user privacy. 
                        Join us on our mission to change the digital advertising landscape.
                    </p>
                <p>Lorem ipsum odor amet, consectetuer adipiscing elit. 
                    Habitant iaculis iaculis lacinia ante primis orci est torquent.</p>
            </div>

            <div className='content-block'>
                <h1>Why Encryptiest</h1>
                <div className="why-us row">
                    <div className="col-md-6 mb-4">
                        <div className="stats p-3 text-center">
                        <h2>400 million</h2>
                        <p>Different types of ads being blocked each day.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="stats p-3 text-center">
                        <h2>400 million</h2>
                        <p>Different types of ads being blocked each day.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="stats p-3 text-center">
                        <h2>400 million</h2>
                        <p>Different types of ads being blocked each day.</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="stats p-3 text-center">
                        <h2>400 million</h2>
                        <p>Different types of ads being blocked each day.</p>
                        </div>
                    </div>
                    </div>

                    <div className = "spacer layer1"></div>
            </div>

            

            <div className = "investors-links">
                            <Link to="/introduction">Introduction Page</Link> <br/>
                            <Link to="/FAQ">Want to know more?</Link> <br/>
                            <Link to="/survey">Survey page</Link> <br/>
                            <Link to="/advertiser-signup">Advertiser Signup page</Link> <br/>
                            <Link to="/publisher-signup">Publisher Signup page</Link> <br/><br/><br/><br/>
                            <Link to="/">Return To Homepage</Link>
            </div>
        

            <Footer />

        </div>
    );
}

export default Investors;
