import './app.css';
import React, { useState , useEffect } from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate  } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

const PaymentComponent = () => {
  const [paymentData, setPaymentData] = useState([]);
  const userId = Cookies.get('user_id');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`http://localhost:8083/api/payments-read-by-user/${userId}`)
      .then(response => {
        setPaymentData(response.data);
      })
      .catch(error => {
        console.error('Error fetching payment data:', error);
      });
  }, [userId]);

  if(paymentData.length === 0) {
    return (
      <button onClick={() => navigate('/payment-add')} className='publisher-button'>Add Payment Details</button>
    );
  } else {
    return(
      <div className='publisher-card-info'>
          {paymentData.map((payment, index) => (
          //Update later with the expected payment info
          <div key={index}>
              Lorem ipsum dolor sit amet
          </div>
          ))}
      </div>
    );
  }
}


const CampaignComponent = () => {
  const [campaignData, setCampaignData] = useState([]);
  const userId = Cookies.get('user_id');

  // To test table without api call uncomment this and then comment out the useEffect below
  /*
  useEffect(() => {
    const testData = [
      {
        _id: '1',
        name: 'Summer Sale Campaign',
        current_costs: 1500.75,
        spend_limit: 2000.00,
        activated: true,
      },
      {
        _id: '2',
        name: 'Winter Promotion',
        current_costs: 500.50,
        spend_limit: 800.00,
        activated: false,
      },
      {
        _id: '3',
        name: 'Black Friday Deals',
        current_costs: 3000.00,
        spend_limit: 5000.00,
        activated: true,
      },
      {
        _id: '4',
        name: 'New Year Countdown',
        current_costs: 1200.00,
        spend_limit: 1500.00,
        activated: false,
      },
      {
        _id: '5',
        name: 'Spring Collection Launch',
        current_costs: 2500.25,
        spend_limit: 3000.00,
        activated: true,
      },
    ];
    setCampaignData(testData);
  }, []);
  */

  useEffect(() => {
    axios.get(`http://localhost:8083/api/campaigns-read-by-user/${userId}`)
      .then(response => {
        setCampaignData(response.data);
      })
      .catch(error => {
        console.error('Error fetching websites data:', error);
      });
  }, [userId]);
  //Comment out useEffect above this line

  const handleCheckboxChange = (id) => {
    setCampaignData((prevData) =>
      prevData.map((row) =>
        row._id === id ? { ...row, activated: !row.activated } : row
      )
    );

    axios.patch(`http://localhost:8083/api/campaigns-update/${_id}`, {
      activated
    })
      .then(response => {
        console.log('Updated activation status:', response.data);
      })
      .catch(error => {
        console.error('Error updating activation status:', error);
      });
  };

  return (
        <table>
            <thead>
                <tr>
                    <th>Campaign Name</th>
                    <th>Amount spent</th>
                    <th>Spending cap</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
              {campaignData.map((row) => (
                <tr key={row._id} style={{ opacity: row.activated ? 1 : 0.5 }}>
                    <td>
                        <a href={`http://localhost:8082/campaign/${row._id}`} target="_blank" rel="noopener noreferrer">{row.name}</a>
                    </td>

                    <td>
                    {`$${row.current_costs.toFixed(2)}`}
                    </td>

                    <td>
                    {`$${row.spend_limit.toFixed(2)}`}
                    </td>
                    
                    <td>
                        <div className="popup">
                        <div>
                            <input
                            type="checkbox"
                            checked={row.activated}
                            onChange={() => handleCheckboxChange(row._id)}
                            />
                            <span className="popup-text">
                            {row.activated ? 'Deactivate this campaign' : 'Activate this campaign'}
                            </span>
                        </div>
                        </div>
                    </td>
                </tr>
              ))}
            </tbody>
        </table>
  )
}

function AdvertiserHome(){

  const userId = Cookies.get('user_id');
  const [companyName, setCompanyName] = useState('');
  const navigate = useNavigate();

  axios.get(`http://localhost:8083/api/user-read-by-id/${userId}`)
  .then(response => {
    setCompanyName(response.data.company_name);
  });


  {/* ---------- CONTENT BLOCK ---------- */}
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  //    \/ \/ \/ \/ \/ \/ \/ \/ \/ \/ \/   //

  const [username, setUsername] = useState('');
  const [userType, setUserType] = useState(''); // Tracks user type

  useEffect(() => {
    // Create a new user in /api/user-create with type Advertiser.
    // Uncomment test case and input your created username
    // and comment out the other line to test ur user.
    // Create another user with type Publisher to test blocking function on publishers.
    // Test a random username that isn't valid to see if it blocks users not logged in.

    const username = Cookies.get('username');
    //const username = 'test_username'; //testing line

    
    console.log('Retrieved user_id from cookies:', username); // Prints cookie in log

    if (!username) {
      console.log('No user_id found, redirecting to signup...'); // Debugging
      navigate('/advertiser-redirect');
    } else {
      axios.get(`http://localhost:8083/api/user-get-type/${username}`)

      .then(response => {
        const {user_type} = response.data;

        if (!response.data || !user_type) {
          // Handle case where username is invalid or doesn't exist
          console.log('Username does not exist, redirecting...');
          navigate('/advertiser-redirect');
        } 
        else if(user_type == 'Advertiser'){
          setUserType(user_type);
          console.log('User is an Advertiser'); // Debugging
        } else {
          console.log('User is not an Advertiser, redirecting...'); // Debugging
          navigate('/advertiser-redirect');
        }
      })
      .catch(error => {
        console.error('Error fetching user type or username does not exist:', error);
        navigate('/advertiser-redirect');
      });
    }
  }, [navigate, username]);

  //    /\ /\ /\ /\ /\ /\ /\ /\ /\ /\ /\   // 
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  {/* ------ END OF CONTENT BLOCK ------- */}

  return(
    <div className="PublisherHome">
        <Header />
        <h1>
            Welcome, {companyName}
        </h1>

        <div className='PublisherHome-payment'>
            <PaymentComponent />
        </div>

        <div>
            <button onClick={() => navigate('/campaign-add')} className='publisher-button'>Create New Campaign</button>
        </div>

        <div className='PublisherHome-websites'>
            <CampaignComponent />
        </div>
        <Footer />
    </div>
  )
}

export default AdvertiserHome;
