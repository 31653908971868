    import { marked } from 'marked';
    import  down from './Faq.md'
    import React, { useState, useEffect } from 'react';
    import { ChevronDown } from 'lucide-react';
    import DOMPurify from 'dompurify';
    import Header from './header';
    import WhiteHeader from './whiteHeader';
    import Footer from './footer';
    import { motion } from 'framer-motion';
    import 'bootstrap/dist/css/bootstrap.min.css';
    import Accordion from 'react-bootstrap/Accordion';
    import './app.css';

    function sanitizeMarkdown(markdown) {
        return DOMPurify.sanitize(marked(markdown));
    }

    const faqData =  [
        {
            question: "What is Encryptiest?",
            answer: "Encryptiest is a privacy-focused advertising playform that respects user data and consent. "
        },
        {
            question: "How does Encryptiest protect my data?",
            answer: "We use end-to-end encryption to ensure your data is secure at all times.  "
        },
        {
            question: "Can I customize my advertising preferences?",
            answer: "Yes, users can tailor their advertising preferences to receive more relevant content."
        },
        {
            question: "How can I become a partner with Encryptiest?",
            answer: "If you're interested in becoming a partner, please contact our team via the Careers page, Advertiser signup page, and Publisher page."
        }
    ]


    function FAQ(){
            let [content, setContent] = useState('');

            useEffect(() => {
                fetch(FaqMarkdown)
                .then((response) => response.text())
                .then((text) => setContent(text));
            }, []);
        
        return (
            <div className='faq'>
                <WhiteHeader /> 
                <div className='faq-hero'>
                <motion.div
                    initial={{ y:50, opacity: 0.5, scale: 1}}
                    animate={{ y: 0,opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, ease: 'easeOut' }}
                >   
                    <h4>Encryptiest FAQs</h4>
                    <h1>Frequently Asked Questions</h1>
                    <p>Get to know more about Encryptiest!</p>
                </motion.div>
                </div>
                
                <div className = "accordion-container container faq-accordion">
                    {faqData.map((item) => (
                        <Accordion>
                            <Accordion.Header>
                                <h2>{item.question}</h2>
                            </Accordion.Header>
                            <Accordion.Body className = "text-start">
                                {item.answer}
                            </Accordion.Body>
                        </Accordion>
                    ))}
                </div>
                <Footer />

            </div>
        )
    }

    export default FAQ;