import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import Header from './header';
import Footer from './footer';

function sanitizeMarkdown(markdown) {
    return DOMPurify.sanitize(marked(markdown));
}

function Websites() {
    const [content, setContent] = useState('');

    useEffect(() => {
        // Simulate fetching markdown content for the websites list
        const fetchData = async () => {
            // Assuming you have a markdown file or an endpoint to fetch the content from
            const response = await fetch('/path-to-your-websites-markdown.md'); // Update this path
            const text = await response.text();
            setContent(text);
        };

        fetchData();
    }, []);

    return (
        <div className='websites'>
            <Header />

            <div className='websites-title'>
                <h1>Visit these respectful websites!</h1>
                <p>The websites below respect your privacy and would love to have you as a visitor.</p>
            </div>

            <div className='content-block'>
                <div className='website-entry'>
                    <img src="" alt="Website Screenshot" className="website-image" />
                    <div className="website-description">
                        <a href="https://lorem_ipsum.com" target="_blank" rel="noopener noreferrer">Website 1</a>
                        <p>Check out this wonderful website that has signed up with us!</p>
                    </div>
                </div>
                <div className='website-entry'>
                    <img src="" alt="Website Screenshot" className="website-image" />
                    <div className="website-description">
                        <a href="https://lorem_ipsum.com" target="_blank" rel="noopener noreferrer">Website 2</a>
                        <p>Check out this wonderful website that has signed up with us!</p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Websites;
