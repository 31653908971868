import React from 'react'
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Button from 'react-bootstrap/Button';
import waveImage from './graphics/wave-haikei.svg';
import aboutImage from './images/bluewhale.png';
import Card from 'react-bootstrap/Card';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';

function About(){
    return (
  
    <div className="about-page">
        <Header/>
        <div className = "d-flex align-items-center justify-content-center hero-section">
            <div className = "d-flex flex-column align-items-center text-center" id = "slogan">
                <motion.div
                    initial={{ y:50, opacity: 0.5, scale: 1}}
                    animate={{ y: 0,opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, ease: 'easeOut' }}
                >
                <h1>
                    a closer look at encryptiest.
                </h1>
                </motion.div>
            </div>
        
        </div>

        <div className='spacer layer1'></div>

        <div className = "d-flex align-items-center justify-content-center about-content">


            <div className = "about-us">
                <h2>About us</h2>
                <p>Digital advertising in its current form does not protect consumers during their normal digital activity. This has lead to various limiting factors to targeted advertising including common sense privacy regulations.

Using Fully Homomorphic Encryption, Encryptiest can manipulate encrypted data and return a meaningful result. This means their personal data is never available to anyone else. The result is targeted advertising that doesn't betray user privacy.
                </p>
            </div>

            <div className = "about-image">
                <img 
                    src = {aboutImage}></img>
            </div>

        </div>
        <Footer/>
    </div>
    )
}

export default About;
