import './app.css';
import {Link} from "react-router-dom";
import Header from './header';
import Footer from './footer';
import React from 'react';

function SurveyConfirmation() {
    return (
    <div className = "survey-confirmation">

        <Header />
    
        <div className = "survey-confirmation-description">
            <h1>Congratulations!</h1>
            <h2>You will now see Encryptiest's relevant ads on websites that support our privacy-protecting protocol. Your information will never leave your browser unencrypted.</h2>
            <br />
            <h2>Click <a href="/websites">here</a> for a list of websites using our protocol!</h2>
        </div>
        
        <Footer />
    </div>

    );
    
}

export default SurveyConfirmation;