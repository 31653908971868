import './app.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate  } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

function EmailConfirmation () {

    const [message, setMessage] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const userId = Cookies.get('user_id');
    const usernameCookie = Cookies.get('username');
    const passwordCookie = Cookies.get('password');
    axios.post(`http://localhost:8083/api/user-read-by-id/`, {
        _id: userId
      }).then(response => {
        setUsername(response.data.username);
    });


    const sendConfirmationCode = async () => {
        try{
            const response = await axios.get(`http://localhost:8083/api/user-send-email-confirmation/${username}`, {
            });

            if (response.status >= 200 && response.status < 300) {
                if (response.data.email_confirmation_code_sent)
                {
                    setMessage('New confirmation code has been sent');
                }
                else 
                {
                    setMessage('Failed to send confirmation code. Please try again.');
                }
            }
            else {
                setMessage('Unexpected response status: ' + response.status);
            }
        }
        catch (error) {
            console.error('Error sending confirmation code:', error);
            setMessage('Error sending confirmation code. Please try sending a new confirmation code.');
        }
    };

    const loginUser = async () => {

        try {
            const response = await axios.post(`http://localhost:8083/api/user-login/`, {
                username: usernameCookie,
                password: passwordCookie
            });
            
            if (response.status >= 200 && response.status < 300) {
                const token = response.data.token;
                Cookies.set('login-token', token, {expires:365, sameSite:'strict', secure: true});
                Cookies.remove('username', { path: '/email-confirmation', expires:365, sameSite:'strict', secure: true});
                Cookies.remove('password', { path: '/email-confirmation', expires:365, sameSite:'strict', secure: true});
                console.log('Login successful:', response.data);
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };
    

    const handleConfirm = async (e) => {
        e.preventDefault();
        try{
            const response = await axios.patch(`http://localhost:8083/api/user-confirm-email-code/`, {
                _id: userId,
                email_confirmation_code: confirmationCode
            });

            if (response.status >= 200 && response.status < 300) {

                setMessage('Email has been confirmed');
                loginUser();
                navigate('/confirmation-login'); //Link to confirmtaion-login when page is created
            }
    }
    catch (error) {
        console.error('Error confirming email code:', error.response ? error.response.data : error.message);
        setMessage('Confirmation failed. Maybe try sending a new confirmation code?');
    }

    };

    useEffect(() => {
        sendConfirmationCode();
    }, [username]);
    

    return(
        <div className="email-confirmation">
            <Header />

            <p>
            Please enter the confirmation code we sent to your email. 
            This code is required to complete your account setup.
            </p>

            <div className='email-confirmation-form'>
                <form>
                    <input
                    type='text'
                    id='confirmation-code'
                    className='signup-textbox'
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    ></input>
                    <label htmlFor="confirmation-code">Enter email code</label>
                </form>

                <div className='buttonArea'>
                    <br></br>
                    <button type="button" className='new-code-button' onClick={sendConfirmationCode}>Send New Confirmation Code</button>
                    <br></br>
                    <button type='submit' className='confirmation-code-submit' onClick={handleConfirm}>Confirm</button>
                </div>

                <p>{message}</p>
            </div>

            <Footer />
        </div>
    )
}

export default EmailConfirmation;