import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import Header from "./header";
import Footer from "./footer";
import Button from 'react-bootstrap/Button';
import { motion } from 'framer-motion';
import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import { ArrowRight } from 'lucide-react';
import { ArrowLeft } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';



const NewsItem = ({ title, date, content, media }) => (
    <div className="news-item">
      <div className="news-item-date">
        {date}
      </div>
      <div className="news-item-content">
        <h2 className="news-item-title">{title}</h2>
        {media && (
          <div className="news-item-media">
            {media.type === 'image' ? (
              <img src={media.url} alt={title} />
            ) : media.type === 'video' ? (
              <video controls>
                <source src={media.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </div>
        )}
        <p className="news-item-text">{content}</p>
      </div>
      <div className="d-flex align-items-center news-item-divider"></div>
    </div>
);


function News() {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    useEffect(() => {
        const sampleNews = [
          {
            title: "Encryptiest Launch Announcement",
            date: "September 15, 2024",
            content: "We're excited to announce the launch of Encryptiest, a cutting-edge encryption solution designed to safeguard your data with the highest level of security. Whether you're a business or an individual, Encryptiest offers seamless, user-friendly encryption tools to protect sensitive information, ensuring privacy and peace of mind in an increasingly digital world. Stay secure, stay encrypted with Encryptiest",
            media: { type: 'image', url: image1 }
          },
          {
            title: "Looking for New Members to Work With",
            date: "September 30, 2024",
            content: "We're excited to announce that we're looking for passionate and talented individuals to join our team! If you're driven, creative, and eager to collaborate on innovative projects, we want to hear from you. Join us and be part of a dynamic team where your skills and ideas can make a real impact. Let’s build something great together!"
          },
          {
            title: "Expansion of Encryptiest",
            date: "October 10, 2024",
            content: "We're thrilled to announce the expansion of Encryptiest! Due to increasing demand and the success of our initial launch, we're broadening our services and capabilities to offer even more robust encryption solutions. With new features, enhanced security protocols, and expanded support for businesses and individuals alike, Encryptiest is taking data protection to the next level. Stay tuned for more updates as we continue to innovate and secure the digital future!",
            media: { type: 'image', url: image2 }
          },
          {
            title: "Encryptiest Launches New Features",
            date: "November 25, 2024",
            content: "We're excited to announce that Encryptiest has launched a range of new features to enhance your data security experience! These updates include advanced encryption protocols, improved user interface for easier navigation, and expanded support for more file types and platforms. With these new capabilities, Encryptiest continues to set the standard for cutting-edge, user-friendly encryption solutions. Your data security just got even stronger!",
            media: {type: 'video', url: "https://www.youtube.com/watch?v=4wulhMus5zo&ab_channel=Zaddox_"}
          },
          {
            title: "Welcoming Our New Sponsors",
            date: "November 31, 2024",
            content: "We're excited to announce and extend a warm welcome to our new sponsors! Their support and partnership will play a vital role in helping us continue to innovate and expand our mission. With their backing, we’re confident that we’ll reach new heights and create even more impactful solutions. Thank you for believing in our vision and joining us on this exciting journey!"
          },
          {
            title: "Final Launch of Encryptiest!",
            date: "December 20, 2024",
            content: "We're thrilled to announce the final launch of Encryptiest! After months of development and refinement, Encryptiest is now fully ready to revolutionize data security. With cutting-edge encryption technology and a seamless user experience, we’re excited to provide businesses and individuals with the ultimate protection for their sensitive information. The future of secure communication starts now—welcome to Encryptiest!",
            media: { type: 'image', url: image3 }
          }
        ];
    
        setNews(sampleNews);
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = news.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(news.length / itemsPerPage);

    const nextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
        scroll.scrollTo('news-container', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: 500,
          containerId: 'news-container'
      });

    };

    const prevPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
        scroll.scrollTo('news-container', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
      });
    };



    return (
        <div className="news-page">
            <Header/>
            <div className="d-flex align-items-center justify-content-center hero-section">
                <div className="d-flex flex-column align-items-center text-center" id="slogan">
                    <motion.div
                        initial={{ y:50, opacity: 0.5, scale: 1}}
                        animate={{ y: 0, opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                    <h1 style={{ color: '#f5f5f5' }}>
                        Our Latest Updates
                    </h1>
                    </motion.div>
                </div>
            </div>

            <div className='spacer layer3'></div> 

            <div className="d-flex justify-content-center align-items-center news-info"id = "news">
                <h1 style={{ color: '#f5f5f5' }}>News</h1>
            </div>
    
            <div className="news-container" >
                {currentItems.map((item, index) => (
                    <NewsItem
                        key={index}
                        title={item.title}
                        date={item.date}
                        content={item.content}
                        media={item.media}
                    />
                ))}
                <div className="d-flex justify-content-around align-items-center mt-4 controls">
                    <Button 
                        onClick={prevPage} 
                        disabled={currentPage === 1}
                    >
                        <ArrowLeft className = "left-icon" />
                        Previous
                    </Button>
                    <span style={{ color: '#f5f5f5' }}>Page {currentPage} of {totalPages}</span>
                    <Button 
                        // href = "#news"  
                        onClick={nextPage} 
                        disabled={currentPage === totalPages}
      
                    >
                        Next
                        <ArrowRight className = "right-icon" />

                    </Button>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default News;