import './app.css';
import React, { useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import CustomCheckbox from './custom-checkbox';

/*
api/ campaign-read-by-user/<int:user_id>/ [name='List Campaign entries by user ID'] 
*/

function CampaignAdd() {
  const [campaignName, setCampaignName] = useState('');
  const [spendingLimit, setSpendingLimit] = useState(0.00);
  const [currentSpend, setCurrentSpend] = useState(0.00);

  const [errorMessage, setErrorMessage] = useState('');
  const [textFieldError, setTextFieldError] = useState('');

  const [userAds, setUserAds] = useState([]);
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState([]);

  const navigate = useNavigate();

  const userId = Cookies.get('user_id');


  {/* ---------- CONTENT BLOCK ---------- */}
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  //    \/ \/ \/ \/ \/ \/ \/ \/ \/ \/ \/   //

  const [username, setUsername] = useState('');
  const [userType, setUserType] = useState(''); // Tracks user type

  useEffect(() => {
    // Create a new user in /api/user-create with type Advertiser.
    // Uncomment test case and input your created username
    // and comment out the other line to test ur user.
    // Create another user with type Publisher to test blocking function on publishers.
    // Test a random username that isn't valid to see if it blocks users not logged in.

    const username = Cookies.get('username');
    //const username = 'test_username'; //testing line

    console.log('Retrieved user_id from cookies:', username); // Prints cookie in log

    if (!username) {
      console.log('No username found, redirecting to signup...'); // Debugging
      navigate('/advertiser-redirect');
    } else {
      axios.get(`http://localhost:8083/api/user-get-type/${username}`)

      .then(response => {
        const {user_type} = response.data;

        if (!response.data || !user_type) {
          // Handle case where username is invalid or doesn't exist
          console.log('Username does not exist, redirecting...');
          navigate('/advertiser-redirect');
        } 
        else if(user_type == 'Advertiser'){
          setUserType(user_type);
          console.log('User is an Advertiser'); // Debugging
        } else {
          console.log('User is not an Advertiser, redirecting...'); // Debugging
          navigate('/advertiser-redirect');
        }
      })
      .catch(error => {
        console.error('Error fetching user type or username does not exist:', error);
        navigate('/advertiser-redirect');
      });
    }
  }, [navigate,username]);
  
  //    /\ /\ /\ /\ /\ /\ /\ /\ /\ /\ /\   // 
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  {/* ------ END OF CONTENT BLOCK ------- */}


  /*
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const adResponse = await fetch('http://localhost:8083/api/campaign-read-by-user/`userId`/');
        setUserAds(adResponse.data);
        setSelectedCheckboxOptions(userAds);
      } catch (error) {
        console.error('Error fetching options:', error);
      }};
    fetchOptions();
  }, []);
  */

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = checkEmpty();
    if (!isValid) {
      return;
    }

    try {
        const campaignResponse = await axios.post('http://localhost:8083/api/campaign-create/',{
            user_id: userId,
            name: campaignName,
            spending_limit: spendingLimit,
            current_spend: currentSpend
        });

        if (campaignResponse.status == 201) {
            setCampaignName('');
            setSpendingLimit(0.00);

            navigate('/advertiser-home');
          } else {
            setErrorMessage('Could not create campaign. Wrong response status received.');
          }
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else {
            console.error('Error:', error);
        }
        setErrorMessage('Could not create campaign. Indeterminate error.');
    }
  }

  const checkEmpty = () => {
    let isValid = true;
    if (campaignName === '' && spendingLimit === 0.00) {
        setTextFieldError('The Campaign Name and Spending Limit fields are empty.');
        isValid = false;
    }
    else if (campaignName === '' && spendingLimit != 0.00) {
        setTextFieldError('The Campaign Name field is empty.');
        isValid = false;
    }
    else if (campaignName != '' && spendingLimit === 0.00) {
        setTextFieldError('The Spending Limit field is empty.');
        isValid = false;
    }
    else {
        setTextFieldError('');
    }
    
    return isValid;
  }
    
  return (
        <div className="campaign-add">
            <Header />
            <main>
                <h1>Please specify the parameters of your new advertising campaign.</h1>
                <form onSubmit={handleSubmit}>
                    <div className="advertiseradderror">
                        {errorMessage && <h1>{errorMessage}</h1>}
                        {textFieldError && <h1>{textFieldError}</h1>}
                    </div>
                
                    <div className="campaign-add-input">
                        <input type='text' id='campaign-name' value={campaignName} onChange={(e) => setCampaignName(e.target.value)}></input>
                        <label htmlFor="campaign-name">Campaign name</label>
                    </div>

                    <div className="campaign-add-input">
                        <input type='text' id='spending-limit' value={spendingLimit} onChange={(e) => setSpendingLimit(e.target.value)}></input>
                        <label htmlFor="spending-limit">Spending Limit</label>
                    </div>

                    <button type="submit">Create Campaign</button>
                </form>
            </main>
            <Footer />
        </div>
  );
}

/*
                    <div className="campaign-add-input">
                        <div className="custom-select">
                            <select>
                                {userTargetRules.map((option,index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))};
                                <option> Add New Targeting Profile </option>
                            </select>
                        </div>
                        <label htmlFor="targeting-profile">Targeting Profile</label>
                    </div>

                    <div className="campaign-add-input">
                        <div>
                            <CustomCheckbox 
                            optionsArray={userAds}
                            selectedOptions={selectedCheckboxOptions}
                            setSelectedOptions={setSelectedCheckboxOptions}
                            />
                            <option> Add New Advertisement </option>
                        </div>
                        <label htmlFor="advertisements">Advertisements</label>
                    </div>
*/

export default CampaignAdd;
