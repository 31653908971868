import './app.css';
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import Header from './header';
import Footer from './footer';

function AdvertiserRedirect() {
    return(
        <>
            <Header />
            <Container className = "advertiser-redirect">
                <div className = "advertiser-redirect-message">
                    It looks like you are not logged in or currently registered as an Advertiser. <br/> <br/>
                    To continue, please log in or sign up using one of the buttons below.
                </div>

                <div className="d-flex justify-content-center flex-wrap">
                    <Button 
                        href="/advertiser-signup"
                        variant="success" 
                        className="m-2 px-4 py-2" 
                        style={{ minWidth: '200px' }}
                    >
                        Advertiser Signup page
                    </Button>
                    <Button 
                        href="/login"
                        variant="primary" 
                        className="m-2 px-4 py-2" 
                        style={{ minWidth: '200px' }}
                    >
                        Login
                    </Button>
                </div>
            </Container>
            <Footer />
        </>
    );
}

export default AdvertiserRedirect;