import './app.css';
import React, { useState , useEffect } from 'react';
import axios from 'axios'
import Cookies from 'js-cookie'
import { useNavigate  } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

const PaymentComponent = () => {
  const [paymentData, setPaymentData] = useState([]);
  const userId = Cookies.get('user_id');
  const navigate = useNavigate();


  {/* ---------- CONTENT BLOCK ---------- */}
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  //    \/ \/ \/ \/ \/ \/ \/ \/ \/ \/ \/   //

  const [username, setUsername] = useState('');
  const [userType, setUserType] = useState(''); // Tracks user type

  useEffect(() => {
    // Create a new user in /api/user-create with type Publisher.
    // Uncomment test case and input your created username
    // and comment out the other line to test ur user.
    // Create another user with type Advertiser to test blocking function on publishers.
    // Test a random username that isn't valid to see if it blocks users not logged in.

    const username = Cookies.get('username');
    //const username = 'test_username'; //testing line

    console.log('Retrieved user_id from cookies:', username); // Prints cookie in log

    if (!username) {
      console.log('No username found, redirecting to signup...'); // Debugging
      navigate('/publisher-redirect');
    } else {
      axios.get(`http://localhost:8083/api/user-get-type/${username}`)

      .then(response => {
        const {user_type} = response.data;

        if (!response.data || !user_type) {
          // Handle case where username is invalid or doesn't exist
          console.log('Username does not exist, redirecting...');
          navigate('/publisher-redirect');
        } 
        else if(user_type == 'Publisher'){
          setUserType(user_type);
          console.log('User is an Publisher'); // Debugging
        } else {
          console.log('User is not an Publisher, redirecting...'); // Debugging
          navigate('/publisher-redirect');
        }
      })
      .catch(error => {
        console.error('Error fetching user type or username does not exist:', error);
        navigate('/publisher-redirect');
      });
    }
  }, [navigate,username]);

  //    /\ /\ /\ /\ /\ /\ /\ /\ /\ /\ /\   // 
  //    || || || || || || || || || || ||   //
  //    || || || || || || || || || || ||   //
  {/* ------ END OF CONTENT BLOCK ------- */}

  useEffect(() => {
    axios.get(`http://localhost:8083/api/payments-read-by-user/${userId}`)
      .then(response => {
        setPaymentData(response.data);
      })
      .catch(error => {
        console.error('Error fetching payment data:', error);
      });
    }, [userId]);

  if(paymentData.length === 0) {
    return (
      <button onClick={() => navigate('/payment-add')} className='publisher-button'>Add Payment Details</button>
    );
  } else {
    return(
      <div className='publisher-card-info'>
        {paymentData.map((payment, index) => (
          //Update later with the expected payment info
          <div key={index}>
              Lorem ipsum dolor sit amet
          </div>
        ))}
      </div>
    );
  }
}


const WebsiteComponent = () => {
  const [websiteData, setWebsiteData] = useState([]);
  const userId = Cookies.get('user_id');

  //  To test table without api call uncomment this and then comment out the useEffect below
  /*
  useEffect(() => {
    const testData = [
      {
        _id: '1',
        website_URL: 'https://www.encryptiests.com/',
        revenue: 123.56,
        activated: true,
      },
      {
        _id: '2',
        website_URL: 'https://www.example.com/',
        revenue: 456.78,
        activated: false,
      }
    ];
    setWebsiteData(testData);
  }, []);
  */

  useEffect(() => {
    axios.get(`http://localhost:8083/api/website-read-by-user/${userId}`)
      .then(response => {
        setWebsiteData(response.data);
      })
      .catch(error => {
        console.error('Error fetching websites data:', error);
      });
  }, []);
  //Comment out useEffect above this line

  const handleCheckboxChange = (index) => {
    const updatedData = [...websiteData];
    updatedData[index].activated = !updatedData[index].activated;

    setWebsiteData(updatedData);

    const {activated , _id} = updatedData[index];

    axios.patch(`http://localhost:8083/api/websites-update/${_id}`, {
      activated
    })
      .then(response => {
        console.log('Updated activation status:', response.data);
      })
      .catch(error => {
        console.error('Error updating activation status:', error);
      });
  };

  return (
    <table>
        <thead>
            <tr>
                <th>Website</th>
                <th>Revenue</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
          {websiteData.map((row, index) => (
            <tr key={index} style={{ opacity: row.activated ? 1 : 0.5 }}>
                <td>
                    <a href={row.website_URL} target="_blank" rel="noopener noreferrer">{row.website_URL}</a>
                </td>

                <td>
                    {`$${row.revenue.toFixed(2)}`}
                </td>

                <td>
                    <div className="popup">
                        <div>
                            <input
                                type="checkbox"
                                checked={row.activated}
                                onChange={() => handleCheckboxChange(index)}
                            />
                            <span className="popup-text">
                                {row.activated ? 'Deactivate this website' : 'Activate this website'}
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
          ))}
        </tbody>
    </table>
  )
}

function PublisherHome(){
  const userId = Cookies.get('user_id');
  const [companyName, setCompanyName] = useState('');
  const navigate = useNavigate();

  axios.post(`http://localhost:8083/api/user-read-by-id/`, {
    _id: userId
  })
    .then(response => {
      setCompanyName(response.data.company_name)
    });

  return(
    <div className="PublisherHome">
        <Header />
        <h1>
            Welcome, {companyName}
        </h1>

        <div className='PublisherHome-payment'>
            <PaymentComponent />
        </div>

        <div>
            <button onClick={() => navigate('/website-add')} className='publisher-button'>Add New Website</button>
        </div>

        <div className='PublisherHome-websites'>
            <WebsiteComponent />
        </div>
        <Footer />
    </div>
  )
}

export default PublisherHome;
