import React from 'react';
import './app.css';
import {Link} from "react-router-dom";
import Header from './header';
import Footer from './footer';

import { Container, Button } from 'react-bootstrap';

function Introduction() {
    
    return (
        <>
            <Header />

            <Container className="text-center text-white my-5" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                <h2 className="my-4">Encrypiest made simple</h2>

                <div className="border border-light p-4 mb-5">
                    <p>(diagram or video)</p>
                </div>

                <p className="mb-4">
                    Encryptiest is a platform dedicated to providing respectful and secure advertising solutions.
                    Our approach ensures that your data is always protected, and your privacy is never compromised.
                </p>

                <div className="d-flex justify-content-center flex-wrap">
                    <Button 
                      href="/FAQ"
                      variant="primary" 
                      className="m-2 px-4 py-2" 
                      style={{ minWidth: '200px' }}
                    >
                      Want to know more?
                    </Button>

                    <Button 
                      href="/survey"
                      variant="secondary" 
                      className="m-2 px-4 py-2" 
                      style={{ minWidth: '200px' }}
                    >
                      Survey page
                    </Button>

                    <Button 
                      href="/advertiser-signup"
                      variant="success" 
                      className="m-2 px-4 py-2" 
                      style={{ minWidth: '200px' }}
                    >
                      Advertiser Signup page
                    </Button>

                    <Button 
                      href="/publisher-signup"
                      variant="info" 
                      className="m-2 px-4 py-2" 
                      style={{ minWidth: '200px' }}
                    >
                      Publisher Signup page
                    </Button>
                </div>
            </Container>

            <Footer />
        </>
        
    );
}

export default Introduction;